import React, { useEffect } from "react"

import BlocksContent from "@components/shared/blocksContent"
import { Container } from "@util/standard"
import DemoContactForm from "@components/demo/contactForm"
import Image from "@components/shared/image"
import { ReactComponent as LaptopTop } from "@static/assets/demoWave.svg"
import Layout from "@components/layout"
import { Query } from "@graphql-types"
import SanityIllustrationTextComponent from "@components/shared/SanityIllustrationText"
import { ReactComponent as Top } from "@static/assets/demoWave2.svg"
import { graphql } from "gatsby"
import styled from "styled-components"

interface Props {
  data: Query
}

const StyledTop = styled(Top)`
  position: absolute;
  top: -40px;
  width: 100%;
  @media screen and (max-width: 1600px) {
    display: none;
  }
`

const StyledLaptopTop = styled(LaptopTop)`
  display: none;
  @media screen and (max-width: 1600px) {
    display: block;
    position: absolute;
    top: -0px;
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    top: 60px;
  }
  @media screen and (max-width: 800px) {
    top: 100px;
  }
`

const StyledContainer = styled(Container)`
  p {
    font-size: 18px;
  }
  h1 {
    font-size: 28px;
  }
  li {
    font-size: 18px;
    margin-left: 25px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    .desktop-title {
      display: none;
    }
    p {
      font-size: 16px;
    }
    h1 {
      font-size: 25px;
    }
    li {
      font-size: 16px;
      margin-left: 15px;
    }
  }
`

const StyledIllustration = styled(Container)`
  li {
    font-size: 18px;
    padding-left: 3em !important;
    &:before {
      font-size: 35px;
      line-height: 30px;
      top: 5px;
      font-family: "Red Hat Display Bold";
    }
  }
  h2 {
    margin-bottom: 25px !important;
  }

  .featureText {
    padding: 25px;
  }
`

const MobileH1 = styled.h1`
  display: none;

  @media screen and (max-width: 600px) {
    font-size: 25px;
    display: block;
    width: 90%;
    margin: 0 auto 30px auto;
  }
`

const BookADemo = ({ data }: Props) => {
  const { sanityBookADemoNew } = data
  if (sanityBookADemoNew == null) return null

  return (
    <>
      <Layout>
        <StyledTop />
        <StyledLaptopTop />
        <Container
          margin="300px auto 0 auto"
          width="60%"
          zIndex={100}
          isTabletColumn
          tabletWidth="100%"
          maxWidth="1200px"
          mobileMargin="100px auto 0 auto"
          mobileFlexDirection="column-reverse"
        >
          {sanityBookADemoNew.richText && (
            <StyledContainer
              flexDirection="column"
              width="50%"
              tabletWidth="90%"
              padding="0 35px 0 0"
            >
              <h1 className="desktop-title">{sanityBookADemoNew.title}</h1>
              <BlocksContent data={sanityBookADemoNew.richText} />
            </StyledContainer>
          )}
          <Container width="50%" tabletWidth="100%" mobileWidth="90%">
            <DemoContactForm />
          </Container>
          <MobileH1 className="mobile-title">
            {sanityBookADemoNew.title}
          </MobileH1>
        </Container>
        {sanityBookADemoNew.illustratedCta && (
          <StyledIllustration>
            <SanityIllustrationTextComponent
              feature={sanityBookADemoNew.illustratedCta}
              maxWidth="1200px"
              marginOverride="60px auto"
              paddingOverride="25px"
            />
          </StyledIllustration>
        )}
        {sanityBookADemoNew.testimonial && (
          <Container
            width="70%"
            margin="0px auto 50px auto"
            flexDirection="column"
            maxWidth="1200px"
          >
            <p style={{ fontWeight: 700, textAlign: "center", fontSize: 22 }}>
              {sanityBookADemoNew.testimonial.description}
            </p>
            <Container justifyContent="center">
              {sanityBookADemoNew.testimonial.profileImage && (
                <Image
                  width="90px"
                  data={sanityBookADemoNew.testimonial.profileImage}
                />
              )}
              <p style={{ margin: "auto 0px auto 10px", fontSize: 18 }}>
                {sanityBookADemoNew?.testimonial?.name}
              </p>
            </Container>
          </Container>
        )}
      </Layout>
    </>
  )
}

export default BookADemo

export const query = graphql`
  {
    sanityBookADemoNew {
      illustratedCta {
        ...sanityIllustrationText
      }
      hubspotForm {
        title
        formID
      }
      richText {
        _rawColumnContent
        _type
        _key
      }
      title
      testimonial {
        description
        name
        profileImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
