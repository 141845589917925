// extracted by mini-css-extract-plugin
export var SvgTopLeft = "productTemplate-module--SvgTopLeft--KX9xA";
export var SvgTwo = "productTemplate-module--SvgTwo--xJjJG";
export var bottomMiddle = "productTemplate-module--bottomMiddle--xXtLz";
export var button = "productTemplate-module--button--JNx8U";
export var buttonContainer = "productTemplate-module--buttonContainer--l25fY";
export var colorBlock = "productTemplate-module--colorBlock--4J8BU";
export var colorBlockImg = "productTemplate-module--colorBlockImg--+1xcd";
export var colorBlockWrapperInner = "productTemplate-module--colorBlockWrapperInner--QtMbP";
export var colorButtonContainer = "productTemplate-module--colorButtonContainer--NeM3w";
export var colorImgContainer = "productTemplate-module--colorImgContainer--CNuRe";
export var colorSvgMax = "productTemplate-module--colorSvgMax--e58Uz";
export var colorTextContainer = "productTemplate-module--colorTextContainer--fLwZ6";
export var featureContainer = "productTemplate-module--featureContainer--7Suak";
export var featureImg = "productTemplate-module--featureImg--xBHYO";
export var featureImgContainer = "productTemplate-module--featureImgContainer--2DNjh";
export var featureImgNoMax = "productTemplate-module--featureImgNoMax--+K8sf";
export var featureText = "productTemplate-module--featureText--XvVox";
export var heroImage = "productTemplate-module--heroImage--ff1FF";
export var heroWrapperInner = "productTemplate-module--heroWrapperInner--o8Jl9";
export var imgContainer = "productTemplate-module--imgContainer--PChIJ";
export var isStyleH1 = "productTemplate-module--is-style-h1--UgfkG";
export var isStyleH2 = "productTemplate-module--is-style-h2--RZNoN";
export var isStyleH3 = "productTemplate-module--is-style-h3--o1ZpV";
export var isStyleH4 = "productTemplate-module--is-style-h4--Fjagi";
export var isStyleH5 = "productTemplate-module--is-style-h5--JLTwL";
export var isStyleH6 = "productTemplate-module--is-style-h6--HaKPk";
export var link = "productTemplate-module--link--IuU7o";
export var svgFirst = "productTemplate-module--svgFirst--SaS0K";
export var svgMax = "productTemplate-module--svgMax--xOAZM";
export var textInner = "productTemplate-module--textInner--Mw4Kj";
export var textWrapper = "productTemplate-module--textWrapper--9Uxge";
export var videoWrapper = "productTemplate-module--videoWrapper--Ws7Ys";
export var wrapper = "productTemplate-module--wrapper--b1MCy";
export var wrapperInnerInfo = "productTemplate-module--wrapperInnerInfo--qh4ge";