import { SanityIllustrationText } from "@graphql-types"
import React from "react"

import * as style from "../../global-styless/productTemplate.module.scss"
import BlocksContent from "./blocksContent"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledContainer = styled.div<{
  maxWidth?: string
  hasDropdown?: boolean
  marginOverride?: string
  paddingOverride?: string
}>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ hasDropdown }) => hasDropdown && `box-shadow:0 0 10px rgb(0 0 0 / 12%);`}
  ${({ marginOverride }) => marginOverride && `margin: ${marginOverride};`}
  ${({ paddingOverride }) => paddingOverride && `padding: ${paddingOverride};`}


`

interface Props {
  feature: SanityIllustrationText
  dropShadow?: boolean
  maxWidth?: string
  marginOverride?: string
  paddingOverride?: string
}

const SanityIllustrationTextComponent = (props: Props) => {
  const { feature } = props

  return (
    <StyledContainer
      className={style.featureContainer}
      maxWidth={props.maxWidth}
      hasDropdown={feature?.illustration?.addDropshadow}
      marginOverride={props.marginOverride}
      paddingOverride={props.paddingOverride}
    >
      <div className={style.featureText}>
        <h2>{feature.sectionTitle}</h2>
        {feature.sectionPortableText && (
          <BlocksContent data={feature.sectionPortableText} />
        )}
      </div>
      {feature.illustration && feature.illustration.asset && (
        <div className={style.featureImgContainer}>
          <GatsbyImage
            image={feature.illustration.asset.gatsbyImageData}
            alt=""
            className={
              feature.illustration.fullWidth
                ? style.featureImgNoMax
                : style.featureImg
            }
          />
        </div>
      )}
    </StyledContainer>
  )
}

export default SanityIllustrationTextComponent
